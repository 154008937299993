var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { attrs: { title: _vm.selected, "hide-header": true } },
    [
      _c(
        "el-row",
        { staticClass: "margin-top-15" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.isEdicaoProxyPorCia
                ? _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    },
                    _vm._l(_vm.ListaCompanhias, function (status, index) {
                      return _c(
                        "el-radio-button",
                        {
                          key: index,
                          attrs: {
                            label: status.desc,
                            disabled: status.disabled,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img-cia",
                            class: { "preto-branco": status.disabled },
                            attrs: { src: status.path, height: "15" },
                          }),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.isProxiesPath
            ? _c(
                "el-col",
                { staticClass: "text-right", attrs: { span: 12 } },
                [
                  _c("label", { staticClass: "lb-refresh" }, [
                    _vm._v("Autorefresh " + _vm._s(_vm.refresh ? "On" : "Off")),
                  ]),
                  _c("el-switch", {
                    on: { change: _vm.changeRefresh },
                    model: {
                      value: _vm.refresh,
                      callback: function ($$v) {
                        _vm.refresh = $$v
                      },
                      expression: "refresh",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("table-proxies", {
            ref: "tableProxies",
            attrs: { "id-cia": _vm.idCiaSelecionada },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }