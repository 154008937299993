var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { "leave-active-class": "animated slideOutUp" } },
    [
      _c(
        "div",
        { staticClass: "divLoadingToken" },
        [
          _c("vue-loading", {
            class: "animation-loading-token",
            attrs: { spinner: "double-bounce" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }