var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-starting-992" },
    [
      _c(
        "div",
        { staticClass: "desktop container-input" },
        [
          _c("el-input", {
            ref: "search",
            staticClass: "search-input",
            class: _vm.visibleSearchUpdated
              ? "full-width"
              : "standart-width focusOut",
            attrs: {
              type: "search",
              placeholder: "Pesquisar...",
              size: "large",
              autocomplete: "off",
              "prefix-icon": "el-icon-search",
            },
            on: {
              blur: function ($event) {
                _vm.visibleSearch = false
              },
              focus: function ($event) {
                _vm.visibleSearch = true
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                _vm.visibleSearch = false
              },
              input: _vm.updateSearch,
            },
            model: {
              value: _vm.resultSearch,
              callback: function ($$v) {
                _vm.resultSearch = $$v
              },
              expression: "resultSearch",
            },
          }),
        ],
        1
      ),
      _vm.resultSearch.length > 0 && _vm.visibleSearch
        ? _c(
            "el-card",
            {
              staticClass: "search-content desktop",
              attrs: {
                "body-style": {
                  padding: "0px",
                  minWidth: "550px",
                  maxHeight: "500px",
                  overflow: "auto",
                },
              },
            },
            [
              _vm.results.length > 0 && _vm.visibleSearch
                ? _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Selecione a opção desejada")])]
                  )
                : _vm._e(),
              _c("div", [
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.load,
                        expression: "load",
                      },
                    ],
                    staticClass: "ul-container",
                  },
                  [
                    _c(
                      "li",
                      _vm._l(_vm.results, function ({ item }, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass: "il-container",
                            attrs: { href: item.path },
                            on: {
                              mousedown: function ($event) {
                                $event.preventDefault()
                                _vm.visibleSearch = true
                              },
                              click: _vm.closeSearch,
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-right" }),
                            _c("div", { staticClass: "item-content" }, [
                              _c("h6", [_vm._v(_vm._s(item.title))]),
                              _c("p", [_vm._v(_vm._s(item.description))]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm.results.length === 0 ? _c("el-divider") : _vm._e(),
              _vm.results.length === 0
                ? _c("div", { staticClass: "resultNone" }, [
                    _c("span", [
                      _vm._v("Sem resultados para "),
                      _c("strong", [_vm._v(_vm._s(_vm.resultSearch))]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mobile" },
        [
          _c("div", {}, [
            _c("i", {
              staticClass: "search-mobile el-input__icon el-icon-search",
              attrs: { slot: "prefix" },
              on: {
                click: function ($event) {
                  _vm.visibleMobileSearch = !_vm.visibleMobileSearch
                },
              },
              slot: "prefix",
            }),
          ]),
          _vm.visibleMobileSearch
            ? _c(
                "el-card",
                {
                  staticClass: "search-content",
                  attrs: {
                    "body-style": {
                      padding: "0px",
                      maxHeight: "500px",
                      overflow: "auto",
                    },
                  },
                  on: {
                    blur: function ($event) {
                      _vm.visibleMobileSearch = false
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "search",
                          staticClass: "search-input",
                          attrs: {
                            type: "search",
                            placeholder: "Pesquisar",
                            size: "large",
                          },
                          on: {
                            focus: function ($event) {
                              _vm.visibleMobileSearch = true
                            },
                            input: _vm.updateSearch,
                          },
                          model: {
                            value: _vm.resultSearch,
                            callback: function ($$v) {
                              _vm.resultSearch = $$v
                            },
                            expression: "resultSearch",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "icon-style el-input__icon el-icon-search",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm.results.length > 0
                      ? _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "infinite-scroll",
                                rawName: "v-infinite-scroll",
                                value: _vm.load,
                                expression: "load",
                              },
                            ],
                            staticClass: "ul-container",
                          },
                          [
                            _c(
                              "li",
                              _vm._l(_vm.results, function ({ item }, index) {
                                return _c(
                                  "a",
                                  {
                                    key: index,
                                    staticClass: "il-container",
                                    attrs: { href: item.path },
                                    on: {
                                      mousedown: function ($event) {
                                        $event.preventDefault()
                                        _vm.visibleMobileSearch = true
                                      },
                                      click: _vm.closeSearch,
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-right" }),
                                    _c("div", { staticClass: "item-content" }, [
                                      _c("h6", [_vm._v(_vm._s(item.title))]),
                                      _c("p", [
                                        _vm._v(_vm._s(item.description)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm.results.length === 0
                    ? _c("div", { staticClass: "resultNone" }, [
                        _c("span", [
                          _vm._v("Sem resultados para "),
                          _c("strong", [_vm._v(_vm._s(_vm.resultSearch))]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }