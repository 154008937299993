var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.unreadCount > 0
        ? _c("el-badge", { staticClass: "notification-badge" }, [
            _c(
              "span",
              {
                staticClass: "icon-bell",
                on: { click: _vm.toggleNotificationPanel },
              },
              [_c("icon", { attrs: { name: "bell", scale: "1" } })],
              1
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.unreadCount > 0,
                    expression: "unreadCount > 0",
                  },
                ],
                staticClass: "blink",
              },
              [_vm._v(_vm._s(_vm.unreadCount))]
            ),
          ])
        : _c(
            "span",
            {
              staticClass: "icon-bell",
              on: { click: _vm.toggleNotificationPanel },
            },
            [_c("icon", { attrs: { name: "bell", scale: "1" } })],
            1
          ),
      _c(
        "el-drawer",
        {
          staticClass: "notification-panel",
          attrs: {
            visible: _vm.showNotificationPanel,
            direction: "rtl",
            size: 410,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showNotificationPanel = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "custom-drawer-header" }, [
              _vm._v(" Notificações "),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "el-drawer__container notification-panel-container",
            },
            [
              _c(
                "p",
                { staticClass: "left-aligned" },
                [
                  _vm.unreadCount > 0
                    ? _c(
                        "el-tag",
                        {
                          attrs: { type: "danger", effect: "dark", strong: "" },
                        },
                        [_vm._v(_vm._s(_vm.unreadCount) + " notificações")]
                      )
                    : _vm._e(),
                  _vm.unreadCount > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "ml-2",
                          staticStyle: { color: "var(--el-color-success)" },
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.markAllAsRead },
                        },
                        [_vm._v("Marcar todas como lido")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.unreadCount === 0
                ? _c("div", { staticClass: "no-notifications" }, [
                    _c("img", {
                      attrs: {
                        src: "https://cdn-icons-png.flaticon.com/128/3264/3264806.png",
                        alt: "Sem notificações",
                      },
                    }),
                    _c("h5", [
                      _vm._v("Pode descansar, não existe nenhuma notificação"),
                    ]),
                  ])
                : _vm._l(
                    _vm.paginatedNotifications,
                    function (notification, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "notification-item" },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "card-container",
                              attrs: { shadow: "hover" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card-top",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImagePreview(
                                        notification.image
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("el-avatar", {
                                    staticClass: "card-left center-image",
                                    staticStyle: {
                                      height: "50px",
                                      width: "80px",
                                      "line-height": "60px",
                                      display: "flex",
                                      "justify-content": "center",
                                      "align-items": "center",
                                    },
                                    attrs: {
                                      shape: "square",
                                      src: notification.image,
                                    },
                                  }),
                                  _c("div", { staticClass: "buttons-left" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mx-1",
                                        attrs: { size: "small", tag: "i" },
                                      },
                                      [
                                        _c("el-icon", {
                                          staticClass: "calendar-icon",
                                          attrs: { name: "date" },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(notification.date)
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "time-since" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.timeSince(notification.date)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "card-right" }, [
                                _c("p", [_vm._v(_vm._s(notification.title))]),
                              ]),
                              _c("div", { staticClass: "card-description" }, [
                                _c("p", [
                                  _vm._v(_vm._s(notification.description)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card-bottom" }, [
                                _c(
                                  "div",
                                  { staticClass: "buttons-right" },
                                  [
                                    notification.webUrl
                                      ? _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            icon: "el-icon-link",
                                            type: "default",
                                            plain: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.learnMore(
                                                notification.id
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-check",
                                        type: "default",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.markAsRead(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.imagePreviewVisible,
                    width: "50%",
                    "before-close": _vm.closeImagePreview,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.imagePreviewVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.currentPreviewImage },
                  }),
                ]
              ),
              _vm.unreadCount > 0
                ? _c("el-pagination", {
                    attrs: {
                      "page-size": 3,
                      total: _vm.unreadCount,
                      layout: "total, prev, pager, next",
                    },
                    on: { "current-change": _vm.handlePageChange },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }