var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "form-container": !_vm.fullWidth,
        "padding-full-width": _vm.fullWidth,
      },
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "content-header-modal",
            {
              attrs: {
                id: "headerTop",
                "fn-close": _vm.fnClose,
                label: _vm.label,
                "sub-label": _vm.subLabel,
                size: _vm.size,
                "hide-back": _vm.hideBack,
                "hide-default-buttons": _vm.hideDefaultButtons,
                "l-items-dropdown": _vm.lItemsDropdown,
                "msg-loading-dropdown": _vm.msgLoadingDropdown,
              },
              on: {
                guardar: function ($event) {
                  return _vm.$emit("guardar")
                },
              },
            },
            [_vm._t("default")],
            2
          ),
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "animated-400 slideInDown",
                "leave-active-class": "animated-200 slideOutUp",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.headerTopVisible,
                      expression: "!headerTopVisible",
                    },
                  ],
                  staticClass: "float-header",
                  attrs: { id: "header-fixed" },
                },
                [
                  _c(
                    "content-header-modal",
                    {
                      attrs: {
                        "fn-close": _vm.fnClose,
                        label: _vm.label,
                        "sub-label": _vm.subLabel,
                        "show-check": _vm.showCheck,
                        size: _vm.size,
                        "hide-back": _vm.hideBack,
                        "hide-default-buttons": _vm.hideDefaultButtons,
                        "l-items-dropdown": _vm.lItemsDropdown,
                        "msg-loading-dropdown": _vm.msgLoadingDropdown,
                      },
                      on: {
                        guardar: function ($event) {
                          return _vm.$emit("guardar")
                        },
                      },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }