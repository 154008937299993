var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "animated-400 fadeInRight",
        "leave-active-class": "animated-400 fadeOutRight",
      },
    },
    [
      _vm.show
        ? _c(
            "b-row",
            { staticClass: "container-popup" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-close",
                  attrs: { circle: "" },
                  on: {
                    click: function ($event) {
                      return _vm.Fechar()
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close" })]
              ),
              _c("b-col", { attrs: { span: 24 } }, [
                _c("h3", { staticClass: "lb-titulo" }, [
                  _vm._v("Oferta Exclusiva! 🤑"),
                ]),
                _c("p", { staticClass: "lb-descricao" }, [
                  _vm._v(
                    " Não perca essa super oportunidade. Atualize o seu plano agora com 20% DE DESCONTO NO PLANO ANUAL e tenha acesso a funcionalidades profissionais, como domínio próprio (suaempresa.com), venda de passagens com milhas e muito mais! "
                  ),
                ]),
              ]),
              _c("b-col", { attrs: { span: 24 } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#/MeuPlano" },
                    on: {
                      click: function ($event) {
                        return _vm.Fechar()
                      },
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "primary-background-color",
                        attrs: { type: "primary", size: "big" },
                      },
                      [_vm._v("ATUALIZAR AGORA! 🚀")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }