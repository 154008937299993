var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "container-row-table text-right" },
    [
      _vm.proxy.Id
        ? _c(
            "el-button",
            {
              staticClass: "margin-bottom-20",
              attrs: {
                icon: !_vm.editMode ? "el-icon-edit" : "el-icon-check",
                size: "mini",
              },
              on: { click: _vm.ChangeEditMode },
            },
            [_vm._v(" " + _vm._s(!_vm.editMode ? "Editar" : "Concluir") + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          !_vm.editMode
            ? _c(
                "el-table",
                { staticClass: "container-ips", attrs: { data: _vm.ips } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "Id", label: "#", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Config", label: "Proxy" },
                  }),
                ],
                1
              )
            : _c("el-input", {
                attrs: { type: "textarea", rows: 30 },
                model: {
                  value: _vm.textListIp,
                  callback: function ($$v) {
                    _vm.textListIp = $$v
                  },
                  expression: "textListIp",
                },
              }),
        ],
        1
      ),
      _c("dialog-edit-subnet", {
        ref: "dialogEditSubnet",
        on: { confirm: _vm.ResultConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }