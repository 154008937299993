var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isHomeRoute
    ? _c(
        "footer",
        {
          ref: "footer",
          class: {
            footer: !_vm.setFooterAbsolute,
            "footer-absolute": _vm.setFooterAbsolute,
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("div", { staticClass: "container-span" }, [
                  _c(
                    "span",
                    { staticClass: "span-left" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            href: "https://atendimento.moblix.com.br",
                            type: "primary",
                            target: "_blank",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                      _vm._v(" Dúvidas?"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "span-right" },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "link-help",
                          attrs: {
                            href: _vm.Help.url,
                            type: "primary",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.Help.label) + " "),
                          _c("v-icon", {
                            attrs: { name: "external-link-alt" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }