var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "icon-top",
      attrs: { trigger: "click" },
      on: { command: _vm.AcaoAjuda },
    },
    [
      _c(
        "span",
        { staticClass: "el-dropdown-link" },
        [_c("icon", { attrs: { name: "question", scale: "1" } })],
        1
      ),
      _c(
        "el-dropdown-menu",
        { ref: "dropdownMenu", attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c("el-dropdown-item", { attrs: { command: "verTutoriais" } }, [
            _c("i", { staticClass: "el-icon-video-play" }),
            _vm._v(" Central de atendimento "),
          ]),
          _c("el-dropdown-item", { attrs: { command: "abrirChamado" } }, [
            _c("i", { staticClass: "el-icon-edit-outline" }),
            _vm._v(" Abrir chamado "),
          ]),
          _c("el-dropdown-item", { attrs: { command: "agendarCall" } }, [
            _c("i", { staticClass: "el-icon-date" }),
            _vm._v(" Call com o Comercial "),
          ]),
          _c("el-dropdown-item", { attrs: { command: "statusPlataforma" } }, [
            _c("i", { staticClass: "el-icon-circle-check" }),
            _vm._v(" Status da plataforma "),
          ]),
          _c("el-dropdown-item", { attrs: { command: "pesquisaSatisfacao" } }, [
            _c("i", { staticClass: "el-icon-star-off" }),
            _vm._v(" Pesquisa de satisfação "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }