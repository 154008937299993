var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { staticClass: "i-icon", attrs: { trigger: "click" } },
        [
          _c("span", { staticClass: "el-dropdown-link" }, [
            _c("i", { staticClass: "el-icon-circle-plus" }),
          ]),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "botao-add",
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/cliente/-1" } },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v("Cliente"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("C")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "/Negociacao/-1" } },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-money" }),
                        _vm._v("Negociação"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("N")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "/Pages/-1" } },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-document-add" }),
                        _vm._v("Página"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("P")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "/Conteudos/-1" } },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-tickets" }),
                        _vm._v("Post"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("O")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: "/Produto/-1" } },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-guide" }),
                        _vm._v("Produto"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("R")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/Financeiro/contas-a-receber/00000000-0000-0000-0000-000000000000",
                  },
                },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-download" }),
                        _vm._v("Receita"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("E")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/Financeiro/contas-a-pagar/00000000-0000-0000-0000-000000000000",
                  },
                },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "dropdown-item-flex" },
                    [
                      _c("div", { staticClass: "item-name" }, [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v("Despesa"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-atalhos" },
                        [
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("Alt")]
                          ),
                          _c(
                            "el-tag",
                            { attrs: { type: "info", size: "mini" } },
                            [_vm._v("D")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }