var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "DivMenuLateral" } },
    [
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animated-400 slideInLeft",
            "leave-active-class": "animated-200 slideOutLeft",
          },
        },
        [
          _vm.ShowMenu
            ? _c(
                "div",
                {
                  staticClass: "div-mobile-menu",
                  class: { "side-menu-collapsed": _vm.recolher },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left side-menu barra",
                      attrs: { id: "sidMenuBarra" },
                    },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-lateral",
                          attrs: {
                            id: "elMenuLateral",
                            "default-active": "1",
                            collapse: _vm.collapseMenu,
                            "unique-opened": true,
                            "collapse-transition": false,
                          },
                        },
                        [
                          _c("div", { staticClass: "container-logo" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push("/")
                                  },
                                },
                              },
                              [
                                !_vm.recolher
                                  ? _c("img", {
                                      staticClass: "img-logo",
                                      attrs: {
                                        height: "28px",
                                        src: require("../../assets/img/logo-moblix_branco.svg"),
                                        alt: "Moblix",
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "img-logo",
                                      attrs: {
                                        height: "28px",
                                        src: require("../../assets/img/logo-x.png"),
                                        alt: "Moblix",
                                      },
                                    }),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "container-subitens-menu",
                              attrs: { id: "divContainerSubitensMenu" },
                            },
                            _vm._l(_vm.MenuItens, function (item, $index) {
                              return _c("div", { key: $index }, [
                                !_vm.IsHide(item)
                                  ? _c(
                                      "div",
                                      { staticClass: "content-menu-item" },
                                      [
                                        item.category && !_vm.recolher
                                          ? _c(
                                              "span",
                                              { staticClass: "lb-category" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.category) + " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return item.fnClickIcon()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    item.iconCategory
                                                      ? _c("icon", {
                                                          staticClass:
                                                            "i-icon icon-category",
                                                          attrs: {
                                                            name: item.iconCategory,
                                                            scale: "0.8",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : !item.subItens ||
                                            !item.subItens.length
                                          ? _c(
                                              "el-menu-item",
                                              {
                                                attrs: {
                                                  index: $index.toString(),
                                                  disabled:
                                                    _vm.IsDisabled(item),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.AbrirPagina(item)
                                                  },
                                                },
                                              },
                                              [
                                                item.icon
                                                  ? _c("icon", {
                                                      staticClass:
                                                        "i-icon icon-menu",
                                                      attrs: {
                                                        name: item.icon,
                                                        scale: "1",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-submenu",
                                              {
                                                attrs: {
                                                  index: $index.toString(),
                                                  disabled:
                                                    _vm.IsDisabled(item),
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _c("icon", {
                                                      staticClass:
                                                        "i-icon icon-menu",
                                                      attrs: {
                                                        name: item.icon,
                                                        scale: "1",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.title)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-menu-item-group",
                                                  _vm._l(
                                                    item.subItens,
                                                    function (
                                                      subItem,
                                                      $subindex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            $index +
                                                            "-" +
                                                            $subindex,
                                                          staticClass:
                                                            "content-sub-menu-item",
                                                        },
                                                        [
                                                          _c(
                                                            "el-menu-item",
                                                            {
                                                              attrs: {
                                                                index:
                                                                  $index.toString() +
                                                                  "-" +
                                                                  $subindex.toString(),
                                                                disabled:
                                                                  _vm.IsDisabled(
                                                                    subItem
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.AbrirPagina(
                                                                      subItem,
                                                                      true
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    subItem.title
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              2
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "transition",
                        [
                          _c("help-box", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.recolher,
                                expression: "!recolher",
                              },
                            ],
                            ref: "helpBox",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }