import axios from "axios";
import LoginService from "./LoginService";
import DateTimeService from "@/services/DateTimeServices";
import UtilService from "@/services/UtilService";

export default class HttpService {
  constructor(source, host, needAuth) {
    this.source = source;
    this._BuildUtil();
    this.host = host ?? process.env.VUE_APP_URL_API;
    this.client = axios.create();
    let token = null;
    if (needAuth) {
      let loginService = new LoginService();
      if (loginService.PegarToken())
        token = "Bearer " + loginService.PegarToken();
    }
    this.client.defaults.headers.get["Authorization"] = token;
    this.client.defaults.headers.delete["Authorization"] = token;
    this.client.defaults.headers.put["Authorization"] = token;
    this.client.defaults.headers.post["Authorization"] = token;
  }

  _BuildUrl(url) {
    if (url.toLowerCase().startsWith("http") || !this.host) return url;
    else return this.util.UrlCombine(this.host, url);
  }

  _BuildUtil() {
    if (!this.util) {
      if (this.source && this.source.$UtilMbx) this.util = this.source.$UtilMbx;
      else this.util = new UtilService(this);
    }
  }

  Post(
    url,
    obj,
    callback,
    callbackErro,
    headers,
    contentType = "application/json"
  ) {
    this.client.defaults.headers.post["Content-Type"] = contentType;
    url = this._BuildUrl(url);
    let _headers = headers
      ? {
          ...this.client.defaults.headers,
          ...headers,
        }
      : this.client.defaults.headers;
    this.client
      .post(url, obj, {headers: _headers})
      .then((response) => {
        callback(response.data);
      })
      .catch((erro) => {
        if (callbackErro) {
          callbackErro(erro);
        } else {
          this._showMessageErro(erro, url);
        }
      });
  }

  Put(url, obj, callback, callbackErro) {
    url = this._BuildUrl(url);
    this.client
      .put(url, obj)
      .then((response) => {
        callback(response.data);
      })
      .catch((erro) => {
        if (callbackErro) {
          callbackErro(erro);
        } else {
          this._showMessageErro(erro, url);
        }
      });
  }

  Get(url, callback, callbackErro, headers) {
    url = this._BuildUrl(url);
    let _headers = headers
      ? {
          ...this.client.defaults.headers,
          ...headers,
        }
      : this.client.defaults.headers;

    this.client
      .get(url, {headers: _headers})
      .then((response) => {
        callback(response.data);
      })
      .catch((erro) => {
        if (callbackErro) {
          callbackErro(erro);
        } else {
          this._showMessageErro(erro, url);
        }
      });
  }

  GetFilter(url, filtro, callback, callbackErro, headers) {
    if (filtro) url += this.JsonToQueryString(filtro);
    return this.Get(url, callback, callbackErro, headers);
  }

  GetAwaitable(url, filtro) {
    url = this.host ? this.host + url : url;
    url += this.JsonToQueryString(filtro);
    return this.client.get(url);
  }

  Delete(url, callback, callbackErro) {
    url = this.host ? this.host + url : url;
    this.client
      .delete(url)
      .then((response) => {
        callback(response.data);
      })
      .catch((erro) => {
        if (callbackErro) {
          callbackErro(erro);
        } else {
          this._showMessageErro(erro, url);
        }
      });
  }

  _showMessageErro(erro) {
    let msg = "";
    if (erro.response && erro.response.statusText) {
      if (erro.response.status === 401) {
        msg = "Erro 401";
      } else msg = erro.response.statusText;
    } else if (
      erro.response &&
      erro.response.data &&
      erro.response.data.error_description
    ) {
      msg = erro.response.data.error_description;
    } else if (erro.response && erro.response.status === 413) {
      msg =
        "O tamanho máximo permitido para imagem é de 3 MB. Por favor, reduza e tente novamente.";
    }
    if (msg) this.source.$message.error(msg);
  }

  JsonToQueryString(json) {
    let ref = this;
    return (
      "?" +
      Object.keys(json)
        .filter((key) => {
          if (json[key] !== "" && json[key] !== undefined && json[key] !== null)
            return key;
        })
        .map((key) => {
          if (Array.isArray(json[key])) {
            let value = "";
            json[key].forEach((i, counter) => {
              value += key + "=" + i;
              if (counter + 1 < json[key].length) value += "&";
            });
            return value;
          } else {
            let value = json[key];
            if (value instanceof Date) {
              if (!ref.dtService) ref.dtService = new DateTimeService();
              value = ref.dtService.FormatDate(json[key], null, null, "-");
            }

            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
          }
        })
        .join("&")
    );
  }
}
