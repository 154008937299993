var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "DragContainerMenu",
      staticClass: "menu-container",
      class: { "animated-menu-item": _vm.animated },
    },
    [
      _c("menu-lateral", {
        ref: "menuLateral",
        attrs: { "pessoa-logada": _vm.pessoaLogada },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }