var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { "enter-active-class": "animated bounceIn" } },
    [
      _c("el-alert", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.msg,
            expression: "msg",
          },
        ],
        staticClass: "margin-top-10",
        attrs: {
          "show-icon": "",
          title: _vm.msg,
          type: "warning",
          closable: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }