var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-help", attrs: { id: "divContainerHelp" } },
    [
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animated zoomIn",
            "leave-active-class": "animated zoomOut",
          },
          on: {
            "after-enter": function ($event) {
              _vm.showIcon = true
            },
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showContainer,
                  expression: "showContainer",
                },
              ],
              staticClass: "content-help",
            },
            [
              _c(
                "el-col",
                [
                  _c(
                    "transition",
                    {
                      attrs: { "enter-active-class": "animated zoomIn" },
                      on: {
                        "after-enter": function ($event) {
                          _vm.builded = true
                        },
                      },
                    },
                    [
                      _c("el-image", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showIcon,
                            expression: "showIcon",
                          },
                        ],
                        staticClass: "img-help margin-bottom-15",
                        attrs: { src: _vm.iconUrl },
                        on: { load: _vm.calcSizeMenu },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.builded
                ? _c(
                    "el-col",
                    [
                      _c(
                        "vue-typed-js",
                        {
                          ref: "vueTyped",
                          attrs: {
                            strings: _vm.frases,
                            typeSpeed: 50,
                            backSpeed: 20,
                          },
                          on: {
                            onComplete: function ($event) {
                              return _vm.typedDone()
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "full-width text-center" }, [
                            _c("span", { staticClass: "typing" }),
                          ]),
                        ]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: { "enter-active-class": "animated fadeInUp" },
                        },
                        [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showPeriodoTesteGratis,
                                  expression: "showPeriodoTesteGratis",
                                },
                              ],
                              staticClass: "spn-free-test",
                            },
                            [
                              _vm._v(
                                "Você tem " +
                                  _vm._s(14 - _vm.parceiro.TempoCadastro) +
                                  " dias de teste grátis!"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: { "enter-active-class": "animated bounceIn" },
                          on: {
                            "after-enter": function ($event) {
                              _vm.showPeriodoTesteGratis =
                                _vm.parceiro.PeriodoAvaliacao
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showButton,
                                  expression: "showButton",
                                },
                              ],
                              staticClass: "margin-top-20",
                              on: { click: _vm.goPage },
                            },
                            [_vm._v("🚀 Upgrade")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }