var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { staticClass: "i-icon", attrs: { trigger: "click" } },
        [
          _c("span", { staticClass: "el-dropdown-link" }, [
            _c("i", { staticClass: "el-icon-menu" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "div",
                { staticClass: "icones-grid" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/Planner/Planner" } },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-badge",
                            {
                              staticClass: "beta-badge",
                              attrs: { value: "beta" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/icons/bot-de-mensagem.png"),
                                  alt: "Bot",
                                },
                              }),
                            ]
                          ),
                          _c("div", [_vm._v("Trip Planner")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/Calendar" } },
                    [
                      _c("el-dropdown-item", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/icons/calendario.png"),
                            alt: "Calendar",
                          },
                        }),
                        _c("div", [_vm._v("Calendário")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }