var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header-modal", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.ShowHeader,
        expression: "ShowHeader",
      },
    ],
    attrs: {
      "fn-close": _vm.fnClose,
      label: _vm.Titulo,
      "sub-label": _vm.Description,
      "hide-back": _vm.hideBack,
      "hide-default-buttons": !_vm.ShowDefaultButtons,
      "l-items-dropdown": _vm.dropdown.itens,
      "msg-loading-dropdown": _vm.dropdown.msgLoading,
    },
    on: { guardar: _vm.Save },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }