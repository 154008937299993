var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "animated-400 fadeIn",
        "leave-active-class": "animated-400 fadeOut",
      },
      on: { enter: _vm.StartWrapperPage },
    },
    [
      !_vm.showFormCadastro
        ? _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLoginPage,
                  expression: "showLoginPage",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.msgLoading,
                  expression: "msgLoading",
                },
              ],
              staticClass: "div-login",
              attrs: {
                "element-loading-text": _vm.msgLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "full-height hideOnlyMobile",
                  attrs: { md: 14 },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "div-login-back-a": _vm.isAfiliadoTrivelo,
                        "div-login-back-b": _vm.isAfiliadoMoblix,
                        "div-login-back":
                          !_vm.isAfiliadoTrivelo || !_vm.isAfiliadoMoblix,
                        "full-height": true,
                      },
                    },
                    [
                      _c("div", { staticClass: "mask mask-1" }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-darker has-mask has-mask-1 h-100 d-flex flex-column justify-content-end card-body",
                        },
                        [
                          _c("div", { staticClass: "mt-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "h1 title mb-3",
                                staticStyle: { color: "white" },
                              },
                              [
                                _vm._v("Mais reservas, "),
                                _c("br"),
                                _vm._v(" clientes mais felizes."),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "white",
                                  "margin-bottom": "60px",
                                  width: "80%",
                                },
                              },
                              [
                                _vm._v(
                                  "Descubra como a Moblix está revolucionando o comércio eletrônico no mercado de turismo. Passagens aéreas, hotéis, pacotes, experiências turísticas, ingressos e muito mais. Você cria sua loja online e começa a vender no mesmo dia."
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "avatar-login" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar1.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white media-overlap",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar2.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white media-overlap",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar3.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white media-overlap",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar6.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white media-overlap",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar5.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-login media-middle media-sm media-circle border-white media-overlap",
                              },
                              [
                                _c("img", {
                                  staticClass: "image-placeholder",
                                  attrs: {
                                    src: require("../../assets/img/avatar/avatar4.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "small mt-3",
                              staticStyle: { color: "white" },
                            },
                            [
                              _vm._v(
                                "Milhares de pessoas e empresas utilizam a Moblix, agora é a sua vez. "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "col-form-login full-height",
                  attrs: { id: "colFormLogin", md: 10, sm: 24 },
                },
                [
                  _c("div", { staticClass: "wrapper-parent" }, [
                    _c(
                      "div",
                      { ref: "wrapperPage", staticClass: "wrapper-page" },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              "enter-active-class": "animated fadeInDown",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showLogo,
                                    expression: "showLogo",
                                  },
                                ],
                                staticClass: "text-center",
                              },
                              [
                                _c("a", { attrs: { href: "#/login" } }, [
                                  _c("img", {
                                    staticClass: "logo-position",
                                    attrs: {
                                      height: "130",
                                      src: require("../../assets/img/logo-moblix_azul.svg"),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "transition",
                          {
                            attrs: {
                              "enter-active-class": "animated-200 fadeIn",
                              "leave-active-class": "animated-200 fadeOut",
                            },
                          },
                          [
                            _c("form-login", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFormLogin,
                                  expression: "showFormLogin",
                                },
                              ],
                              on: {
                                exibirLembrarSenha: function ($event) {
                                  return _vm.transitionForms(1)
                                },
                                LoginSuccess: function ($event) {
                                  return _vm.LoginRealizado()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "transition",
                          {
                            attrs: {
                              "enter-active-class": "animated-200 fadeIn",
                              "leave-active-class": "animated-200 fadeOut",
                            },
                          },
                          [
                            _c("form-lembrar-senha", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.lembrarSenha,
                                  expression: "lembrarSenha",
                                },
                              ],
                              on: {
                                VoltarLogin: function ($event) {
                                  return _vm.transitionForms(2)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "transition",
                          {
                            attrs: {
                              "enter-active-class": "animated zoomIn",
                              "leave-active-class": "animated-200 fadeOut",
                            },
                          },
                          [
                            _c("form-mudar-senha", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.Hash,
                                  expression: "Hash",
                                },
                              ],
                              attrs: { hash: _vm.Hash },
                              on: {
                                Voltar: function ($event) {
                                  return _vm.fecharFormMudarSenha()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            "el-row",
            [
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class": "animated fadeIn",
                    "leave-active-class": "animated-100 fadeOut",
                  },
                },
                [
                  _c("novo-parceiro", {
                    on: {
                      setMsgLoading: function ($event) {
                        _vm.msgLoading = $event
                      },
                      LoginSuccess: function ($event) {
                        return _vm.LoginRealizado()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }