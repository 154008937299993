var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      {
        class: { "full-width-content": _vm.forceSubscription },
        attrs: { id: "wrapper" },
      },
      [
        _vm.ShowMenuEsquerdo
          ? _c("drag-menu-mobile", {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.clickOutMenu,
                  expression: "clickOutMenu",
                },
              ],
              ref: "sideMenu",
              attrs: { "pessoa-logada": _vm.PessoaLogada },
              on: {
                change: function ($event) {
                  _vm.menuLateralAberto = $event
                },
              },
            })
          : _vm._e(),
        _c("cabecalho", {
          ref: "divCabecalho",
          attrs: {
            id: "div-cabecalho",
            show: !_vm.IsExternalPage(),
            "usuario-logado": _vm.PessoaLogada,
            "plano-atual": _vm.PlanoAtual,
          },
          on: {
            done: function ($event) {
              _vm.showContent = true
            },
          },
        }),
        !_vm.IsExternalPage() &&
        _vm.usuarioAutenticado &&
        _vm.logadoComoVerificado
          ? _c(
              "div",
              {
                staticClass: "content-pagebackground-container",
                class: {
                  "content-page": _vm.menuLateralAberto || _vm.util.IsCompact(),
                  "content-no-menu":
                    !_vm.menuLateralAberto && !_vm.util.IsCompact(),
                },
              },
              [
                _c(
                  "transition",
                  { attrs: { "enter-active-class": "animated fadeIn" } },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showContent,
                            expression: "showContent",
                          },
                        ],
                        staticClass: "content",
                        attrs: { id: "div-content" },
                        on: { scroll: _vm.$refs.divCabecalho.handleScroll },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "margin-top-10",
                            class: { container: _vm.setContainer },
                          },
                          [
                            _c("header-page", { ref: "headerPage" }),
                            _c("router-view", {
                              on: {
                                upgradePlano: function ($event) {
                                  return _vm.upgradePlano($event)
                                },
                                changeMarketplace: function ($event) {
                                  return _vm.changeMarketplace($event)
                                },
                                changeTrivelo: function ($event) {
                                  return _vm.changeTrivelo($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("rodape", { attrs: { id: "div-rodape" } }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.IsExternalPage()
          ? _c("router-view", {
              on: {
                LoginSuccess: _vm.PegarUsuario,
                SetupConcluido: _vm.AbrirComponentes,
              },
            })
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingMainPage,
                expression: "loadingMainPage",
              },
            ],
            staticClass: "div-background-loading",
          },
          [_c("loading-page")],
          1
        ),
        _c("loading-token", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.usuarioAutenticado && !_vm.IsExternalPage(),
              expression: "!usuarioAutenticado && !IsExternalPage()",
            },
          ],
        }),
        _c("modal-submenu", {
          attrs: {
            title: "canais de venda",
            modalVisible: _vm.showModalCanais,
            gridData: _vm.dataModalCanais,
          },
          on: {
            updateModalVisible: function ($event) {
              _vm.showModalCanais = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }