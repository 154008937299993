var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      staticClass: "navTop navHeader",
      class: {
        "header-small": _vm.$screen.xs || _vm.$screen.sm,
        "margin-bottom-10": !_vm.$screen.xs && !_vm.$screen.sm,
      },
    },
    [
      _c("el-col", { attrs: { xs: 18, span: 16 } }, [
        !_vm.hideBack
          ? _c(
              "div",
              { staticClass: "container-button" },
              [
                _c("el-button", {
                  staticClass: "btn-back",
                  attrs: { type: "info", plain: "", icon: "el-icon-back" },
                  on: {
                    click: function ($event) {
                      return _vm.fnClose(false)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { class: { "container-label": !_vm.hideBack } }, [
          _vm.label
            ? _c(
                "span",
                {
                  staticClass: "spn-title",
                  class: { "margin-top-10": !_vm.subLabel },
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
          _vm.subLabel
            ? _c("span", { staticClass: "spn-subtitle" }, [
                _vm._v(_vm._s(_vm.subLabel)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("el-col", { attrs: { xs: 6, span: 8 } }, [
        !_vm.hideDefaultButtons
          ? _c(
              "div",
              { staticClass: "text-right margin-top-10" },
              [
                !_vm.$screen.xs && !_vm.$screen.sm
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "medium", icon: "el-icon-close" },
                            on: {
                              click: function ($event) {
                                return _vm.fnClose(true)
                              },
                            },
                          },
                          [_vm._v("Sair ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "medium",
                              type: "primary",
                              icon: "el-icon-check",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("guardar")
                              },
                            },
                          },
                          [_vm._v("Salvar ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-dropdown",
                      {
                        staticClass: "margin-bottom-10",
                        attrs: { trigger: "click" },
                        on: { command: _vm.handleCommand },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-more-outline",
                          },
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: true } },
                              [_vm._v("Salvar")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: false } },
                              [_vm._v("Descartar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            )
          : _vm.lItemsDropdown && _vm.lItemsDropdown.length
          ? _c(
              "div",
              [
                _c("dropdown-header-modal", {
                  key: _vm.$route.fullPath,
                  attrs: {
                    "l-dropdown": _vm.lItemsDropdown,
                    "msg-loading": _vm.msgLoadingDropdown,
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "right-slot div-slot" },
              [_vm._t("default")],
              2
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }