var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animated slideInDown",
            "leave-active-class": "slide-out-bottom",
          },
          on: {
            "after-enter": function ($event) {
              return _vm.$emit("done")
            },
          },
        },
        [
          _vm.show && _vm.UsuarioLogado
            ? _c(
                "el-row",
                {
                  staticClass: "container-header",
                  class: {
                    "container-header-min": !_vm.MenuOpen,
                    "shadow-div-header": _vm.scrolled,
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "container-flex",
                      attrs: { xs: 6, span: 12 },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "a-expand-header show-starting-992",
                          on: {
                            click: function ($event) {
                              return _vm.showLeftMenu()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-d-arrow-left icon-menu",
                            style: `transform: rotate(${
                              _vm.MenuOpen ? 0 : 180
                            }deg);`,
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "hide-starting-992" },
                        [
                          _c("el-button", {
                            staticClass: "btn-icon-menu",
                            attrs: { type: "text", icon: "el-icon-menu" },
                            on: {
                              click: function ($event) {
                                return _vm.showLeftMenu()
                              },
                            },
                          }),
                          _c("img", {
                            staticClass: "img-logo-x",
                            attrs: {
                              src: require("@/assets/img/logo-x-azul.svg"),
                              alt: "Moblix",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.UsuarioLogado
                        ? _c("search-field", {
                            staticClass: "search-container",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 18, span: 12 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "header-container-right text-right" },
                        [
                          _c(
                            "div",
                            { staticClass: "icon-container" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "box-item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Ver meu site",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-top icon-top-a",
                                      attrs: { target: "_blank" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.VerSite()
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        staticClass: "i-icon",
                                        attrs: { name: "external-link-alt" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.UsuarioLogado.IdPermissao === 1 ||
                              _vm.UsuarioLogado.IdPermissao === 3
                                ? _c("botao-add", {
                                    staticClass: "icon-top icon-top-a",
                                  })
                                : _vm._e(),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "box-item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Outros Apps",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("botao-apps", {
                                    staticClass: "icon-top icon-top-a",
                                  }),
                                ],
                                1
                              ),
                              _vm.UsuarioLogado.IdPermissao === 1 ||
                              _vm.UsuarioLogado.IdPermissao === 3
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "box-item",
                                      attrs: {
                                        effect: "dark",
                                        content: "Configurações",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-top icon-top-a",
                                          attrs: { href: "#/Agencia" },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-setting",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "a",
                                { staticClass: "icon-top icon-top-a" },
                                [_c("notification-badge")],
                                1
                              ),
                              _c("botao-ajuda", {
                                staticClass: "icon-top icon-top-a",
                              }),
                              _c("dropdown-usuario-atual", {
                                staticClass: "icon-top icon-top-a drop-user",
                                attrs: { "usuario-logado": _vm.UsuarioLogado },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("pop-up-notificacao", {
        attrs: { show: _vm.showMigrarPlano },
        on: {
          close: function ($event) {
            _vm.showMigrarPlano = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }