var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      staticClass: "card-container",
      class: { "no-padding-bottom": _vm.noPaddingBottom },
      style: { height: _vm.vHeight },
    },
    [
      !_vm.hideHeader
        ? _c("div", { staticClass: "card-title" }, [
            _c("div", { staticClass: "div-title" }, [
              _c(
                "h2",
                {
                  staticClass: "title",
                  class: { "is-link": _vm.titleUrl },
                  on: {
                    click: function ($event) {
                      return _vm.GoUrl()
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.title) + "  "),
                  _vm.titleUrl
                    ? _c("i", { staticClass: "el-icon-link" })
                    : _vm._e(),
                ]
              ),
              _vm.subtitle
                ? _c("span", {
                    staticClass: "sub-title",
                    domProps: { innerHTML: _vm._s(_vm.subtitle) },
                  })
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "slot-actions" },
              [_vm._t("header-action")],
              2
            ),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }